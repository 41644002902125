.single-container {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 40px;
    background-color: #f3f3f3;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.text-center {
    text-align: center;
    font-weight: bold;
    margin-bottom: 40px;
}

.single-product-data {
    text-align: left;
}

.single-product-media {
    text-align: left;
}
.single-product-media div{
    margin-bottom: 10px;
}

.single-product-video {
    margin-bottom: 20px;
}

iframe{
    max-width: 100%;
    max-height: 100%;
}

.single-btn {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.single-btn:hover {
    background-color: #0056b3;
}

.single-fi-message-square {
    position: fixed;
    bottom: 20px;
    right: 20px;
    color: white;
    background-color: #007bff;
    width: 2.5rem;
    height: 2.5rem;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.single-fi-message-square:hover {
    background-color: #0056b3;
}

.single-contact-form {
    position: fixed;
    bottom: 80px;
    right: 20px;
    width: 500px;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.single-contact-inputs {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.single-contact-inputs input,
.single-contact-inputs textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.single-contact-inputs button {
    align-self: flex-end;
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.single-contact-inputs button:hover {
    background-color: #218838;
}

