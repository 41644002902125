/* Container for the entire section */
.section {
    padding: 2rem;
    background-color: #f8f8f8; /* Light grey background for the section */
  }
  
  /* Container for the grid */
  .container {
    display: grid;
    gap: 3rem; /* Spacing between grid items */
  }
  
  /* Three-column grid layout */
  .grid-three-column {
    grid-template-columns: repeat(3, 1fr); /* Three equal columns */
  }
  
  /* Responsive design: Adjust columns on smaller screens */
  @media (max-width: 1024px) {
    .grid-three-column {
      grid-template-columns: repeat(2, 1fr); /* Two columns for medium screens */
    }
  }
  
  @media (max-width: 768px) {
    .grid-three-column {
      grid-template-columns: 1fr; /* One column for small screens */
    }
  }
  