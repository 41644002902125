#footer {
    font-size: 14px;
    background: #37517e;
  }
  
  #footer .footer-newsletter {
    padding: 50px 0;
    background: #f3f5fa;
    text-align: center;
    font-size: 15px;
    color: #444444;
  }
  
  #footer .footer-newsletter h4 {
    font-size: 24px;
    margin: 0 0 20px 0;
    padding: 0;
    line-height: 1;
    font-weight: 600;
    color: #37517e;
  }
  
  #footer .footer-newsletter form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 50px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.06);
    text-align: left;
  }
  
  #footer .footer-newsletter form input[type=email] {
    border: 0;
    padding: 4px 8px;
    width: calc(100% - 100px);
  }
  
  #footer .footer-newsletter form input[type=submit] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px;
    background: #47b2e4;
    color: #fff;
    transition: 0.3s;
    border-radius: 50px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }
  
  #footer .footer-newsletter form input[type=submit]:hover {
    background: #209dd8;
  }
  
  
  
  #footer .footer-top .footer-contact {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-contact h3 {
    font-size: 28px;
    margin: 0 0 10px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    text-transform: uppercase;
    font-weight: 600;
    color: #37517e;
  }
  
  #footer .footer-top .footer-contact p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Jost", sans-serif;
    color: #5e5e5e;
  }
  
  
  #footer .footer-top .footer-links {
    margin-bottom: 1rem;
  }
  
  #footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  #footer .footer-top .footer-links ul a i {
    color: #f5f5f5;
    font-size: 18px;
  }
  
  #footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  
  #footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
  }
  
  #footer .footer-top .footer-links ul a {
    text-decoration: none;
    font-size: 16px;
    color: #ffffff;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
  }
  
  #footer .footer-top .footer-links ul a:hover {
    text-decoration: none;
    color: #47b2e4;
  }
  
  #footer .footer-top .social-links a {
    text-decoration: none;
    font-size: 18px;
    display: inline-block;
    background: #47b2e4;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
  }
  
  #footer .footer-top .social-links a:hover {
    text-decoration: none;
    background: #209dd8;
    color: #fff;
    text-decoration: none;
  }
  
  #footer .footer-bottom {
    padding-top: 30px;
    padding-bottom: 30px;
    color: #fff;
  }
  
  #footer .copyright {
    float: left;
  }
  
  #footer .credits {
    float: right;
    font-size: 13px;
  }
  
  #footer .credits a {
    transition: 0.3s;
  }
  
  @media (max-width: 768px) {
    #footer .footer-bottom {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  
    #footer .copyright,
    #footer .credits {
      text-align: center;
      float: none;
    }
  
    #footer .credits {
      padding-top: 4px;
    }
  }