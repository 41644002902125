/* Container for the filter section */
.filter-section {
  padding: 1rem;
  background-color: #f8f8f8; /* Light grey background */
  border: 1px solid #ddd; /* Light border */
  border-radius: 8px; /* Rounded corners */
  margin-bottom: 1.5rem; /* Space below the filter section */
}

/* Filter search input */
.filter-search input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 1rem; /* Space below the search input */
  font-size: 1rem;
}

/* Filter category section */
.filter-category,
.filter-company {
  margin-bottom: 1rem; /* Space below each section */
}

.filter-category h3,
.filter-company h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: #333;
}

/* Align checkboxes and labels */

.filter-category .model-type .model-item{
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
}


/* Select dropdown */
.filter-company--select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

/* Clear filters button */
.filter-clear {
  margin-top: 1rem;
}

.filter-clear .btn {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.filter-clear .btn:hover {
  background-color: #0056b3;
}

/* Responsive design */
@media (max-width: 768px) {
  .filter-section {
    padding: 0.5rem;
  }

  .filter-search input,
  .filter-company--select,
  .filter-clear .btn {
    font-size: 0.9rem;
    padding: 0.4rem;
  }

  .filter-category h3,
  .filter-company h3 {
    font-size: 1rem;
  }

  .filter-category label {
    font-size: 0.9rem;
  }
}
