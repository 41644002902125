/* Container for the entire section */
.section {
    padding: 2rem;
    background-color: #f8f8f8; /* Light grey background for the section */
  }
  
  /* Container for the grid */
  .container {
    display: grid;
    gap: 2rem; /* Spacing between list items */
  }
  
  /* List item card style */
  .card {
    display: flex;
    align-items: center;
    padding: 1rem;
    background-color: #ffffff; /* White background for the card */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Light shadow for the card */
    border-radius: 8px; /* Rounded corners for the card */
  }
  
  /* Two-column layout for the card */
  .grid-two-column {
    display: grid;
    grid-template-columns: 280px 1fr; /* Fixed width for the image, rest for the text */
    gap: 1rem; /* Spacing between image and text */
  }
  
  /* Image styling */
  figure {
    margin: 0; /* Remove default margin */
    width: 10rem;
    height: 10rem;
  }
  
  figure img {
    width: auto;
    height: 100%;
    border-radius: 8px; /* Rounded corners for the image */
  }
  
  /* Card data styling */
  .card-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .card-data h3 {
    margin: 0 0 0.5rem 0; /* Margin below the heading */
    font-size: 1.25rem; /* Font size for the heading */
  }
  
  .card-data p {
    margin: 0 0 1rem 0; /* Margin below the paragraph */
    color: #555555; /* Grey color for the text */
  }
  
  /* Button styling */
  .btn-main {
    align-self: start; /* Align the button to the start of the flex container */
  }
  
  .btn-read {
    padding: 0.5rem 1rem;
    background-color: #007bff; /* Blue background for the button */
    color: #ffffff; /* White text color for the button */
    border: none;
    border-radius: 4px; /* Rounded corners for the button */
    cursor: pointer;
    text-decoration: none; /* Remove underline from the link */
  }
  
  .btn-read:hover {
    background-color: #0056b3; /* Darker blue background on hover */
  }
  
  /* Responsive design: Adjust layout on smaller screens */
  @media (max-width: 768px) {
    .grid-two-column {
      grid-template-columns: 1fr; /* One column layout for small screens */
    }

  
    .card {
      flex-direction: column; /* Stack image and text vertically */
    }
    
    figure{
      width: 100%;
    }

    figure img {
      width: 100%;
      object-fit: cover;
    }
  }
  