.sort-section {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Distribute space between items */
    padding: 1rem;
    background-color: #f8f8f8; /* Light grey background for the sort section */
    border-bottom: 1px solid #e0e0e0; /* Light border at the bottom */
  }
  
  /* Style for the grid/list buttons */
  .sorting-list--grid {
    display: flex;
    align-items: center;
  }
  
  .sorting-list--grid .btn {
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
  }
  
  .sorting-list--grid .btn .icon {
    font-size: 1.25rem;
  }
  
  /* Active button style */
  .sorting-list--grid .active {
    background-color: #007bff;
    color: #fff;
  }
  
  /* Product data style */
  .product-data {
    font-size: 1rem;
    color: #333;
  }
  
  /* Sort selection style */
  .sort-selection {
    display: flex;
    align-items: center;
  }
  
  .sort-selection form {
    display: flex;
    align-items: center;
  }
  
  .sort-selection--style {
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
  }
  