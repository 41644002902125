/* Ensure the NavLink takes the full height of the grid cell */
.grid-nav-link {
  display: block;
  height: 100%;
  text-decoration: none; 
}

/* Make sure the grid box takes the full height */
.grid-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

/* Card styling */
.grid-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  border: none; /* Remove border */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Light shadow */
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

/* Card hover effect */
.grid-card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

/* Card image styling */
.grid-card img {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 200px; /* Fixed height for images */
  object-fit: cover; /* Ensure image covers the area */
}

/* Card body styling */
.grid-card-body {
  padding: 2rem;
  text-align: center;
  flex-grow: 1; /* Ensure body grows to take up remaining space */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
}

/* Caption styling */
.grid-caption {
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 0.5rem;
}

/* Title styling */
.grid-card-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;
  margin: 0;
  flex-grow: 1; /* Ensure title grows to take up available space */
  display: flex;
  align-items: center; /* Center title vertically */
  justify-content: center; /* Center title horizontally */
}
